<template>
  <div>
    <v-row>
      <v-col cols="8" xs="12">
        <breadcrumbs :items="dataBreadcrumbs" />
      </v-col>
      <v-col cols="4" xs="12" align="right">
      </v-col>
    </v-row>
    <v-row>
      <v-col xs="12" class="col-xs-12">
        <h2 style="font-size: 25px !important;">Information Détaillé sur l'Affiliate N° : {{affiliate.id}}</h2>
      </v-col>
      <v-col xs="12" class="col-xs-12">
      </v-col>
    </v-row>
    <v-row class="user-bio-panel">
      <!-- user profile -->
      <v-col cols="4" class="bg-white border-right border-1">
        <v-card-text :loading="loading">
          <v-card-title class="justify-center flex-column">
            <span class="mb-2">{{ affiliate.user.username }}</span>
            <v-chip
                :color="affiliate.status.color"
                :class="`v-chip-light-bg text-sm font-weight-semibold  text-capitalize`"
            >{{ affiliate.status.label }}</v-chip>
          </v-card-title>
          <v-card-text>

            <v-btn
                block
                small
                color="primary"
                class="me-3"
                @click="dialog = true"
            >
              Modifier le Status
            </v-btn>
            <v-card-title class="right">
              <h4 class="text-xl font-weight-semibold">
                Détails
              </h4>
              <v-spacer></v-spacer>
              <disponibility-array></disponibility-array>
            </v-card-title>
            <v-dialog
                v-model="dialog"
                max-width="500px"
            >
              <AffiliateDetail
                  :statues="statues"
                  :instanceaffiliate="affiliate"/>
            </v-dialog>

            <v-divider></v-divider>

            <v-list class="mb-n2">
              <v-list-item
                  dense
                  class="mb-n2"
              >
                <span class="font-weight-medium me-2">Nom complet :</span>
                <span class="text--secondary">{{ affiliate.user.firstname }} {{ affiliate.user.lastname }}</span>
              </v-list-item>
              <v-list-item
                  dense
                  class="mb-n2"
              >
                <span class="font-weight-medium me-2">Numero de telephone :</span>
                <span class="text--secondary">{{ affiliate.user.phonenumber }}</span>
              </v-list-item>
              <v-list-item
                  dense
                  class="mb-n2"
              >
                <span class="font-weight-medium me-2">Type de vehicule :</span>
                <span class="text--secondary">{{ affiliate.car.name }}</span>
              </v-list-item>
              <v-list-item
                  dense
                  class="mb-n2"
              >
                <span class="font-weight-medium me-2">Proprietaire :</span>
                <span class="text--secondary">{{ affiliate.owner ? 'Oui' : 'Non' }}</span>
              </v-list-item>
              <v-list-item
                  dense
                  class="mb-n2"
              >
                <span class="font-weight-medium me-2">Commission :</span>
                <span class="text--secondary">{{ affiliate.commission }}</span>
              </v-list-item>
              <v-list-item
                  dense
                  class="mb-n2"
              >
                <span class="font-weight-medium me-2">Enregistré le :</span>
                <span class="text--secondary">{{ affiliate.created_at }}</span>
              </v-list-item>
            </v-list>

          </v-card-text>
          <v-card-text>
            <h4 class="text-xl font-weight-semibold mb-2">
              Statistique
            </h4>

            <v-divider></v-divider>

            <v-list>
              <v-list-item
                  dense
                  class="px-0 mb-n2"
              >
                <statistique-affiliate :statisticsData="statisticsData" />
              </v-list-item>
            </v-list>

          </v-card-text>
        </v-card-text>
      </v-col>
      <v-col lg="8" class="bg-white">
        <v-tabs
            v-model="tab"
            background-color="transparent"
            color="basil"
            grow
        >
          <v-tab
              v-for="item in tabs"
              :key="item.key"
          >
            {{ item.name }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item
              key="order"
          >
            <v-card
                color="basil"
                flat
            >
              <v-card-text> historique des commandes</v-card-text>
              <order-list v-if="affiliate.id" :affiliate="affiliate" :countries="[affiliate.user.country]"></order-list>

            </v-card>
          </v-tab-item>
          <v-tab-item
              key="transaction"
          >

            <v-card-title class="align-start">
              <span>Type de transaction</span>
              <v-spacer></v-spacer>

              <v-btn
                  icon
                  small
                  class="me-n3 mt-n2"
              >
                <v-icon>
                  {{ icons.mdiDotsVertical }}
                </v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-row
                  color="basil"
                  flat
              >
                <v-col lg="12">
                  <v-radio-group dense row>
                    <v-checkbox dense v-for="tt in transactiontypes" :key="tt.id"
                                :label="tt.name"
                                :value="tt.name"
                    ></v-checkbox>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row>
                <v-card-text>
                  <div
                      v-for="(earning,index) in totalEarning"
                      :key="earning.avatar"
                      :class="`d-flex align-start ${index >0 ? 'mt-8':''}`"
                  >
                    <v-avatar
                        rounded
                        size="38"
                        color="#5e56690a"
                        class="me-4"
                    >
                      <v-icon
                          color="warning"
                          height="20">
                        {{ earning.avatar }}
                      </v-icon>
                    </v-avatar>

                    <div class="d-flex align-center flex-grow-1 flex-wrap">
                      <div>
                        <h5 class="font-weight-medium">
                          {{ earning.title }}
                        </h5>
                        <span class="text-xs text-no-wrap">{{ earning.subtitle }}</span>
                      </div>

                      <v-spacer></v-spacer>

                      <div class="ms-1">
                        <p class="text--primary font-weight-medium mb-1">
                          {{ earning.earning }}
                        </p>
                        <v-progress-linear class="text-sm"
                                           :value="earning.progress"
                                           :color="earning.color"
                        ></v-progress-linear>
                      </div>
                    </div>
                  </div>
                </v-card-text>
              </v-row>
              <v-row>
                <v-col lg="12">

                  <v-data-table
                      :loading="loadingTrans"
                      :headers="headers"
                      :items="transactionll.listEntity"
                      class="elevation-1"
                      :page.sync="transactionll.current_page"
                      :items-per-page="transactionll.per_page"
                      :sort-by="['id']"
                      @page-count="pageCount = $event"
                      hide-default-footer
                      :sort-desc="true"
                  >
                    <template v-slot:top>
                      <v-toolbar
                          flat>

                      </v-toolbar>
                    </template>

                    <template v-slot:item.status="{ item }">
                      <v-chip>{{ item.status.label }}</v-chip>
                    </template>

                    <template v-slot:item.actions="{ item }">

                      <v-btn @click="validateTrans(item)" small
                             class="primary">
                        <v-icon
                            small
                            class="mr-2"
                        >
                          mdi-check
                        </v-icon>
                        valider
                      </v-btn>

                    </template>

                  </v-data-table>

                </v-col>
              </v-row>
            </v-card-text>
          </v-tab-item>
          <v-tab-item
              key="document"
          >
            <v-dialog width="500" v-model="dialoginvalidate">
              <v-card class="pt-3">
                <v-card-text>
                  <v-textarea outlined label="Raison du rejet" height="100px" v-model="documentItem.comment"></v-textarea>
                </v-card-text>

                <v-card-actions>
                  <v-btn color="error" text :loading="loading" @click="dialoginvalidate = false" small>Fermer</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn :loading="loading" @click="invalidateDocumentConfirm" small>Confirmer le rejet</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-card
                color="basil"
                flat
            >
              <v-card-title>
                Documents
                <v-spacer></v-spacer>
                <v-btn small>Tout Valider</v-btn>
              </v-card-title>
              <v-card-text>

                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                    <tr>
                      <th class="text-left">
                        Modifié le
                      </th>
                      <th class="text-left">
                        Type de document
                      </th>
                      <th class="text-left">
                        Statut
                      </th>
                      <th class="text-left">
                        Action
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr
                        v-for="item in documents"
                        :key="item.name"
                    >
                      <td>{{ item.updated_at }}</td>
                      <td>
                        <a class="v-btn" :href="item.srcDoc">
                          <v-icon>mdi-download</v-icon>
                          {{ item.document_type.name }}
                        </a>
                      </td>
                      <td>
                        <v-chip color="default" v-if="item.status == 0" >En attente de validation</v-chip>
                        <v-chip color="success" v-else-if="item.status == 1" >Validé</v-chip>
                        <v-chip color="error" v-else >Rejeté</v-chip>
                        <p v-if="item.status == 2" >{{item.comment}}</p>
                      </td>
                      <td>
                        <v-btn color="primary" @click="validateDocument(item)" small>
                          <v-icon>mdi-check</v-icon>
                          Validé
                        </v-btn>
                        <v-btn color="error" @click="invalidateDocument(item)" small>
                          <v-icon>mdi-times</v-icon>
                          Invalidé
                        </v-btn>
                      </td>
                    </tr>
                    </tbody>
                  </template>
                </v-simple-table>

              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {Drequest} from "../plugins/Drequest";
import AffiliateDetail from "../components/AffiliateDetail";
import OrderList from "@/views/OrderList";
import { mdiDotsVertical, mdiMenuUp, mdiAlphaGCircle, mdiAlphaCCircle, mdiAlphaOCircle } from '@mdi/js'
import StatistiqueAffiliate from "../components/StatistiqueAffiliate";
import DisponibilityArray from "../components/DisponibilityArray";
import breadcrumbs from "@/components/ui/breadcrumbs";

export default {
  name: "Affiliate",
  components: {DisponibilityArray, StatistiqueAffiliate, AffiliateDetail, OrderList, breadcrumbs},
  beforeMount() {
    this.initialize();
  },
  data: () => ({
    dataBreadcrumbs: [],
    messages: 10,
    totalEarning: [],
    icons: { mdiDotsVertical, mdiMenuUp, mdiAlphaGCircle, mdiAlphaCCircle, mdiAlphaOCircle },
    transactiontypes: [],
    tab: "order",
    tabs: [
      {key: "order", name: "Livraisons"},
      {key: "transaction", name: "Transactions / Paiement"},
      {key: "document", name: "Documents"},
    ],
    affiliate: {user: {}, status: {}, car: {}},
    documents: [],
    documentItem: {},
    statues: [],
    transactiontable: [
      {key: "order", name: "Livraisons"},
      {key: "transaction", name: "Transactions / Paiement"},
      {key: "document", name: "Documents"},
    ],
    headers: [
      {value: "created_at", text: "Cree le"},
      {value: "amount", text: "Montant"},
      {value: "transaction.name", text: "Type de transaction"},
    ],
    transactionll: {},
    loading: true,
    dialog: false,
    loadingTrans: false,
    dialoginvalidate: false,
    commission: 0,
    statisticsData: []
  }),
  methods: {
    validateTrans(item) {
      console.log(item)
    },
    async initialize() {
      this.loading = true;
      this.loadingTrans = true;
      await Drequest.api("dcollection")
          .data({
            "detail.affiliate": this.$route.params.id,
            "lazyloading.affiliate_item": "dfilters=on&affiliate.id:eq=" + this.$route.params.id,
            "lazyloading.wallettransaction": "dfilters=on&wallet.affiliate_id:eq=" + this.$route.params.id,
            "lazyloading.tree-item": "dfilters=on&tree.name:eq=transactiontype",
            "lazyloading.status": "dfilters=on&entity.name:eq=affiliate",
          })
          .raw((response) => {
            this.loading = false;
            this.loadingTrans = false;
            this.dataBreadcrumbs = [
              {
                text: 'Accueil',
                disabled: false,
                href: '/dashboard',
              },
              {
                text: 'Affiliations',
                disabled: false,
                href: '/settings/affiliation',
              },
              {
                text: 'Livreur N° : '+ response.affiliate.affiliate.id,
                disabled: true,
                href: '/',
              }
            ];
            this.totalEarning = [
              {
                avatar: this.icons.mdiAlphaGCircle,
                title: 'Total de gain',
                earning: response.affiliate.totalgain ? response.affiliate.totalgain +'FCFA' : 0 +'FCFA',
                color: 'primary',
                progress: this.percent(response.affiliate.totalgain, response.affiliate)
              },
              {
                avatar: this.icons.mdiAlphaCCircle,
                title: 'Total de crédit',
                earning: response.affiliate.totalcredit ? response.affiliate.totalcredit +'FCFA' : 0 +'FCFA',
                color: 'info',
                progress: this.percent(response.affiliate.totalcredit, response.affiliate)
              },
              {
                avatar: this.icons.mdiAlphaCCircle,
                title: 'Total de commission',
                earning: response.affiliate.commissioncredit ? response.affiliate.commissioncredit +'FCFA' : 0 +'FCFA',
                color: 'warning',
                progress: this.percent(response.affiliate.commissioncredit, response.affiliate)
              },
            ];
            this.affiliate = response.affiliate.affiliate;
            this.transactionll = response.wallettransaction_ll;
            this.documents = response.affiliate_item_ll.listEntity;
            this.transactiontypes = response["tree-item_ll"].listEntity;
            this.statues = response["status_ll"].listEntity;
            Drequest.api("lazyloading.status?dfilters=on&affiliate_id="+response.affiliate.affiliate.id+"&stat=1&entity.name:eq=order")
                .get((response) => {
                  let data = [];
                  response.listEntity.forEach(item => {
                    data.push({
                      title: item.label,
                      total: item.stat,
                      color: item.color,
                    })
                  })
                  this.statisticsData = data
                })
            // this.close();
          })
    },
    percent(value, item){
      return Number (value / (item.commissioncredit + item.totalcredit + item.totalgain));
    },
    editItem(item) {
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },
    validateDocument(item) {
      if (!confirm("Confirmer la validation de ce document?"))
        return 1;

      Drequest.api("update.affiliate-item?id=" + item.id)
          .data({affiliate_item: {status: 1}}).raw((response) => {
        console.log(response);
        item.status = 1;
      })
    },
    invalidateDocument(item) {
      this.documentItem = item;
      this.dialoginvalidate = true
    },
    invalidateDocumentConfirm() {
      this.loading = true
      Drequest.api("update.affiliate-item?id=" + this.documentItem.id)
          .data({affiliate_item: {status: 2, comment: this.documentItem.comment}}).raw((response) => {
        this.documentItem.status = 2;
        this.documentItem.updated_at = response.affiliate_item.updated_at;
        this.loading = false
        this.dialoginvalidate = false
      })
    }
  }
}
</script>

<style scoped>

</style>