<template>
  <div>
    <v-badge
        bordered
        color="#FFB300FF"
        :content="messages"
        :value="messages"
        overlap
    >
      <v-btn
          :disabled="messages <= 0"
          @click="dialogDisponibility = true"
          class="white--text"
          color="#FFB300FF"
          depressed
          small
      >
        disponibilité
      </v-btn>
      <v-dialog
          v-model="dialogDisponibility"
          max-width="1200px"
      >
        <v-card class="pa-8">
          <v-card-title>Liste des disponibilités</v-card-title>
          <!-- actions -->
          <v-card-text class="d-flex align-center flex-wrap pb-0">
            <!-- search -->
            <v-row>
              <v-col lg="4">
                <v-text-field v-model="searchQuery" label="Filtre"></v-text-field>
              </v-col>
              <v-col lg="3">
                <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="interval.date1"
                        label="Du"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="interval.date1"
                      @input="menu2 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col lg="3">
                <v-menu
                    v-model="menu3"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="interval.date2"
                        label="Au"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="interval.date2"
                      @input="menu3 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>

            </v-row>

            <v-spacer></v-spacer>
          </v-card-text>
          <v-data-table
              :loading="loading"
              :headers="headers"
              :items="disponibilities.listEntity"
              class="elevation-1"
              :page.sync="disponibilities.current_page"
              :items-per-page="disponibilities.per_page"
              :sort-by="['id']"
              @page-count="pageCount = $event"
              hide-default-footer
              :sort-desc="true"
          >
            <template v-slot:item.status="{ item }">
              <v-chip
                  :color="item.status.color"
                  :class="`v-chip-light-bg text-sm font-weight-semibold  text-capitalize`"
              >{{ item.status.label }}</v-chip>
            </template>
            <template v-slot:item.affiliate="{ item }">
              <v-chip
                  :color="'FFBE33'"
                  :class="`v-chip-light-bg text-sm font-weight-semibold  text-capitalize`"
              >{{ item.user.firstname }}  {{ item.user.lastname }}</v-chip>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-btn-toggle
                  dense
                  dark
                  class="primary"
              >
                <!--            toogle booton validation and view training bill-->

                <v-btn @click="affectation(item)" small
                       class="primary">
                  <v-icon
                      small
                      class="mr-2"
                  >
                    mdi-arrow-compress-right
                  </v-icon>
                  Affecter
                </v-btn>
              </v-btn-toggle>
            </template>

          </v-data-table>

          <v-card-text class="pt-2">
            <v-row>
              <v-col
                  lg="2"
                  cols="3"
              >

                <v-select
                    v-model="disponibilities.per_page"
                    label="Lignes par page:"
                    :items="[10,20,30,50,80,100, 'Toutes']"
                    hide-details
                    @change="fetchItems()"
                ></v-select>

              </v-col>

              <v-col
                  lg="10"
                  cols="9"
                  class="d-flex justify-end"
              >
                <v-pagination
                    v-model="disponibilities.current_page"
                    total-visible="6"
                    :length="parseInt(disponibilities.pagination )"
                    @input="fetchItems()"
                ></v-pagination>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>

    </v-badge>
  </div>
</template>

<script>
import {Drequest} from "../plugins/Drequest";
const moonLanding = new Date();
export default {
  name: "DisponibilityArray.vue",
  data: () => ({
    dialogDisponibility: false,
    messages: 0,
    interval: {
      company_id: "",
      date1: moonLanding.getFullYear() + "-01-01",
      date2: moonLanding.getFullYear() + "-12-31"
    },
    searchQuery: "",
    menu2: false,
    menu3: false,
    loading: false,
    headers: [
      {text: 'N° Colis', value: 'numberofpackages', sortable: false},

      {text: 'Ville de départ', value: 'departure', sortable: false},
      {text: 'Date de départ', value: 'date_from', sortable: false},
      {text: 'Ville d\'arrivée', value: 'arrival'},
      {text: 'Date d\'arrivée', value: 'date_to'},
      {text: 'Status', value: 'status'},
      {text: 'Poids Max', value: 'maxweight'},
      {text: 'Affiliate', value: 'affiliate.user.firstname'},
      {text: 'Actions', value: 'actions', sortable: false},
    ],
    disponibilities: [],
  }),
  beforeMount() {
    this.initialize();
    },
  methods: {
    fetchItems() {
      this.loading = true;
      Drequest.api("lazyloading.order?dfilters=on&" + this.apiorder)
          .param({
            //"status.id:eq": this.search.status,
            next: this.disponibilities.current_page,
            per_page: this.disponibilities.per_page,
          })
          .get((response) => {
            this.loading = false;
            console.log(response);
            this.disponibilities = response;
          })
    },
    initialize() {
      this.loading = true;
      Drequest.api("dcollection")
          .data({
            "lazyloading.disponibility": "dfilters=on&affiliate.id:eq=" + this.$route.params.id
          })
          .raw((response) => {
            this.messages = response.disponibility_ll.listEntity.length;
            this.disponibilities = response.disponibility_ll;
            this.loading = false;
            // this.close();
          })
    }
  }
}
</script>

<style scoped>

</style>