<template>

  <v-card-text>
    <v-row>
      <v-col
          v-for="data in statisticsData"
          :key="data.title"
          cols="12"
          md="12"
          class="d-flex align-center"
      >
        <v-avatar
            size="30"
            :color="data.color"
            rounded
            class="elevation-1"
        >
          <v-icon
              dark
              color="white"
              size="15"
          >
            {{icons.mdiCircle}}
          </v-icon>
        </v-avatar>
        <div class="ms-3">
          <p class="text-xl mb-0">
            {{ data.title }}
          </p>
          <h3 style="font-size: 1rem !important;" class="text-xs font-weight-semibold">
            {{ data.total }}
          </h3>
        </div>
      </v-col>
    </v-row>
  </v-card-text>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiCircle } from '@mdi/js'

export default {
  props: ["statisticsData"],
  data() {
    return {
      // icons
      icons: {
        mdiCircle,
      },
    }
  }
}
</script>
